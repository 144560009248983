import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsMainSearchComponent } from './cs-main-search.component';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';

@NgModule({
  declarations: [CsMainSearchComponent],
  imports: [
    InputTextModule,
    FormsModule,
    CommonModule,
    ToolbarModule,
    ButtonModule,
    MenuModule,
  ],
  exports: [CsMainSearchComponent],
})
export class CsMainSearchModule {}

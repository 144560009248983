import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_PATHS, STORAGE_KEYS } from '../config/constats';
import { CONFIG } from '../../environments/environment';
import { AuthSuccess } from '../models/generics.model';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private storage: SessionStorageService,
  ) { }

  public auth(data: { username: string; password: string; }): Promise<AuthSuccess> {
    if (!data.password || !data.username) {
      return Promise.reject({ message: 'Missing fields for authentication.'});
    }
    const url = `${CONFIG.API_URL}/${API_PATHS.AUTH}`;
    return this.http.post<AuthSuccess>(url, data).toPromise().then((res) => {
      this.authSuccess(res);
      return res;
    });
  }

  public logout(): Promise<boolean> {
    // TODO: connect with logout API.
    return new Promise((resolve) => {
      setTimeout(() => {
        this.deleteSession();
        resolve(true);
      }, 800);
    });
  }

  public getAuthData(): AuthSuccess {
    const data = this.storage.get(STORAGE_KEYS.AUTH);
    return data;
  }

  public isLoggedIn(): boolean {
    return !!this.storage.get(STORAGE_KEYS.AUTH);
  }

  private authSuccess(data: AuthSuccess): void {
    this.storage.set(STORAGE_KEYS.AUTH, data);
  }

  private deleteSession(): void {
    this.storage.del(STORAGE_KEYS.AUTH);
  }
}

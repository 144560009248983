import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { CONFIG } from '../../environments/environment';
import { SessionStorageService } from './session-storage.service';
import { MessageService } from 'primeng/api';
import { TOAST_KEYS } from '../config/constats';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {
  private whiteListedDomains: string[];

  constructor(
    public auth: AuthService,
    private router: Router,
    private storage: SessionStorageService,
    private messageService: MessageService
  ) {
    const whitelistedURLs = [ CONFIG.API_URL ];
    this.whiteListedDomains = [];

    whitelistedURLs.forEach((url) => {
      const urlObject = new URL(url);
      const parsedUrl = `${urlObject.protocol}//${urlObject.hostname}${(urlObject.port) ? ':' + urlObject.port : ''}`;
      this.whiteListedDomains.push(parsedUrl);
    });
  }

  addToken(request: HttpRequest<any>): HttpRequest<any> {
    const authData = this.auth.getAuthData();

    if (authData) {
      try {
        const url = new URL(request.url);
        const parsedUrl = `${url.protocol}//${url.hostname}${(url.port) ? ':' + url.port : ''}`;

        if (this.whiteListedDomains.indexOf(parsedUrl) > -1) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${authData.access}`
            }
          });
        }
      } catch (e) {
        console.warn(e);
      }
    }
    return request;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(this.addToken(request)).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if needed
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.unauthorized().then();
          } else {
            let errMessage: string;

            if (err.error && err.error.detail) {
              errMessage = err.error.detail;
            } else if (err.message) {
              errMessage = err.message;
            } else {
              errMessage = 'Something went wrong.. please try again';
            }
            const options = { message: errMessage };
            this.showErrorMessage(options).then();
          }
        }
      })
    );
  }

  async unauthorized(): Promise<void> {
    const options = { message: 'Session expired' };
    await this.showErrorMessage(options);
    this.storage.deleteAll();
    this.router.navigate(['login']).then();
  }

  private async showErrorMessage(options): Promise<void> {
    this.messageService.add({
      severity: 'error',
      summary: options.message,
      life: 3000,
      key: TOAST_KEYS.GLOBAL
    });
  }
}

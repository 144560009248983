import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  private storage: Storage = window.sessionStorage;

  constructor() { }

  public set(key: string, data: any): void {
    const parsed = JSON.stringify(data);
    this.storage.setItem(key, parsed);
  }

  public get(key: string): any {
    let result: any = null;
    const data = this.storage.getItem(key);
    if (data) {
      result = JSON.parse(data);
    }
    return result;
  }

  public del(key: string): void {
    return this.storage.removeItem(key);
  }

  public deleteAll(): void {
    this.storage.clear();
  }
}

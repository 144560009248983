/**
 * Returns interpolated URL for usage.
 * @param url string
 * @param data object with key:pair values.
 * @author Luciana Vaggione - 2021
 * @example parseUrl('api/path/%some_id%/%other_param%', { some_id: 1, other_param: 'abc' }) returns 'api/path/1/abc'
 */
export function parseUrl(url: string, data: { [key: string]: string | number } = { }): string {
  if (!url) {
    return '';
  }
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const replaceRegex = new RegExp(`%${key}%`, 'gi');
      const replaceWith = String(data[key]);
      url = url.replace(replaceRegex, replaceWith);
    }
  }
  return encodeURI(url);
}

export const API_PATHS = {
  AUTH: 'api-token-auth/',
  DASHBOARD: {
    TRANSACTIONS_BY_DISTRICT: 'api/stats/districts',
    TRANSACTIONS_BY_SHOP: 'api/stats/shops',
    LATEST_TRANSACTIONS: 'api/transactions/latest',
  },
  USERS: {
    PROFILE: 'api/profile',
    ADD: 'api/user/add',
    UPDATE: 'api/user/update',
    LIST: 'api/user/list',
  },
  SHOPS: {
    ADD: 'api/shops/add',
    UPDATE: 'api/shops/update',
    LIST: 'api/shops/list',
    TRANSACTIONS: 'api/shops/%SHOP_ID%/transactions',
  },
  DISTRICTS: {
    LIST: 'api/districts',
  },
  NGO: {
    ADD: 'api/ngo/add',
    UPDATE: 'api/ngo/update',
    LIST: 'api/ngo/list',
    BALANCE: 'api/ngo/%NGO_ID%/balance',
  },
  DONEES: {
    ADD: 'api/donees/add',
    UPDATE: 'api/donees/update',
    LIST: 'api/ngo/%NGO_ID%/donees',
    TRANSACTIONS: 'api/donees/%DONEE_ID%/transactions',
  },
  PURCHASES: {
    LIST: 'api/tickets/%NGO_ID%/list',
    ADD: 'api/tickets/add',
  },
  WALLETS: {
    FREEZE: 'api/wallets/freeze',
    LIST: 'api/wallets/frozen',
  },
  TOKENS: {
    TOTAL: 'api/tokens/total',
  },
  DEPOSITS: {
    LATEST_DEPOSITS: 'api/deposits/latest',
  },
};

export const STORAGE_KEYS = {
  AUTH: 'cs:auth',
};

export const TOAST_KEYS = {
  TOP_RIGHT: 'toast-top-right',
  GLOBAL: 'global-toast',
};

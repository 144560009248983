<div class="p-grid p-py-3">
  <div class="p-lg-4 p-sm-8 pos-rel">
    <span class="p-input-icon-right cs-main-search" [@openClose]="searchBarOpen ? 'open' : 'closed'"
      (click)="openSearchbar()">
      <i class="pi pi-search no-pointer-ev"></i>
      <input type="text" name="mainSearchInput" ngDefaultControl pInputText [(ngModel)]="searchTxt"
        (ngModelChange)="update.emit($event)" (focus)="openSearchbar()" (focusout)="closeSearchbar()"
        placeholder="Search" class="w-100">
    </span>
  </div>
  <div class="p-lg-8 p-sm-4 p-text-right">
    <div class="user-menu-container">
      <div (click)="menu.toggle($event);">
        <button pButton type="button" icon="pi pi-user" class="p-button-rounded p-button-secondary"></button><span
          class="p-pl-2">{{ userData?.name }}</span>
      </div>
      <p-menu class="user-menu" #menu [popup]="true" [model]="menuItems"></p-menu>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { parseUrl } from '../helpers/urls.helper';
import { CONFIG } from '../../environments/environment';
import { API_PATHS } from '../config/constats';
import { GeneralResponse } from '../models/generics.model';
import { User, UserCreate, UserEdit } from '../models/user.model';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  userData: ReplaySubject<User> = new ReplaySubject(1);

  constructor(private http: HttpClient) {}

  public getProfile(): Promise<User> {
    const path = parseUrl(API_PATHS.USERS.PROFILE);
    const url = `${CONFIG.API_URL}/${path}`;
    return this.http
      .get<User>(url)
      .toPromise()
      .then((response) => {
        this.userData.next(response);
        return response;
      });
  }

  public list(filter?): Promise<User[]> {
    const path = parseUrl(API_PATHS.USERS.LIST);
    const url = `${CONFIG.API_URL}/${path}`;
    return this.http.get<User[]>(url, { params: filter }).toPromise();
  }

  public add(data: UserCreate): Promise<GeneralResponse> {
    const url = `${CONFIG.API_URL}/${API_PATHS.USERS.ADD}`;
    return this.http.post<GeneralResponse>(url, data).toPromise();
  }

  public update(data: UserEdit): Promise<GeneralResponse> {
    const url = `${CONFIG.API_URL}/${API_PATHS.USERS.UPDATE}`;
    return this.http.post<GeneralResponse>(url, data).toPromise();
  }
}

import { Component, OnInit } from '@angular/core';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { CommonService } from './services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public mainSearchQuery: string;
  public showSideNav: boolean;
  public showGlobalSpinner: boolean;

  constructor(
    public commonService: CommonService,
    private primengConfig: PrimeNGConfig
  ) {
    this.showSideNav = false;
    this.showGlobalSpinner = false;
  }
  title = 'com-swap';
  visible = true;
  public items: MenuItem[];

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    this.items = [
      {
        label: 'Dashboard',
        icon: 'fas fa-border-all',
        routerLink: 'dashboard',
      },
      { label: 'Shops', icon: 'fas fa-store', routerLink: 'shops' },
      { label: 'Donees', icon: 'fas fa-users', routerLink: 'donees' },
      { label: 'Freeze wallets', icon: 'fas fa-key' },
      { label: 'Map', icon: 'fas fa-map-marked-alt' },
      { label: 'SDGs Goals', icon: 'fas fa-globe' },
      { label: 'NGOs', icon: 'fas fa-hands', routerLink: 'ngos' },
    ];
  }

  public toggleMenu(): void {
    this.visible = !this.visible;
  }

  public searchUpdate(search: string): void {
    console.log('searchUpdate', search);
  }
}

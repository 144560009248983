import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { UsersService } from '../services/users.service';
import { User } from '../models/user.model';
import { AbstractComponent } from '../views/abstract.component';

@Component({
  selector: 'app-cs-main-search',
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          width: '100%',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          width: '35px',
          opacity: 0.5,
        })
      ),
      transition('open => closed', [animate('0.3s')]),
      transition('closed => open', [animate('0.3s')]),
    ]),
  ],
  templateUrl: './cs-main-search.component.html',
  styleUrls: ['./cs-main-search.component.scss'],
})
export class CsMainSearchComponent extends AbstractComponent implements OnInit {
  @Input() ngModel: string;
  @Output() update = new EventEmitter<string>();

  public searchTxt: string;
  public menuItems: MenuItem[];
  public searchBarOpen = false;
  public userData: User;

  constructor(
    private injector: Injector,
    private authService: AuthService,
    private router: Router,
    private commonService: CommonService,
    private usersService: UsersService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.menuItems = [
      {
        label: 'Profile',
        routerLink: 'profile',
        icon: 'pi pi-user'
      },
      {
        label: 'Logout',
        command: async () => await this.logout(),
        styleClass: 'p-error',
        icon: 'pi pi-fw pi-sign-out'
      },
    ];

    this.usersService.userData.subscribe((userData) => {
      this.userData = userData;
    });
  }

  openSearchbar(): void {
    this.searchBarOpen = true;
  }

  closeSearchbar(): void {
    this.searchBarOpen = false;
  }

  private async logout(): Promise<void> {
    this.commonService.showLoading = true;
    try {
      await this.authService.logout();
      await this.router.navigate(['/login']);
    } catch (e) {
      this.showToastMessage('error', 'An error has ocurred', e.message);
    }
    this.commonService.showLoading = false;
  }
}

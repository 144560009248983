import { Injector } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TOAST_KEYS } from '../config/constats';

export abstract class AbstractComponent {
  protected messageService: MessageService;
  protected constructor(
    private childInjector: Injector,
  ) {
    this.messageService = this.childInjector.get(MessageService);
  }

  protected showToastMessage(
    severity: 'success' | 'info' | 'warn' | 'error',
    summary: string,
    detail: string = '',
    life: number = 3000,
    key = TOAST_KEYS.TOP_RIGHT
  ): void {
    this.messageService.add({
      severity,
      summary,
      detail,
      life,
      key
    });
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private showLoadingSpinner: boolean;

  constructor() {
    this.showLoadingSpinner = false;
  }

  set showLoading(show: boolean) { this.showLoadingSpinner = show; }
  get showLoading(): boolean { return this.showLoadingSpinner; }

}
